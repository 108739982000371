export const Headings = {
  English: {
    h3: "CELEBRATE",
    h1: "The Lantern Festival",
    h2: "with us",
  },

  Chinese: {
    h3: "与我们",
    h1: "一起庆祝",
    h2: "元宵节",
  },
};

/**  
 - Keeping these guys in case UX change their mind about the languages
 
 French: {
   h3: "Célébrez",
   h1: "Le Festival des Lanternes",
   h2: "avec nous",
 },

 Vietnamese: {
   h3: "Kỷ niệm",
   h1: "Lễ hội đèn lồng với",
   h2: "chúng tôi",
 },

 Spanish: {
   h3: "Celebra",
   h1: "El Festival de las Linternas",
   h2: "con nosotros",
 },
*/
