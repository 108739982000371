import React, { FunctionComponent } from "react";
import { HeadingVariant, Heading } from "./types";
import { useClasses } from "@hooks";
import "@styles/Title.scss";

interface TitleProps {
  className: string;
  headings: [Heading];
}

export const Title: FunctionComponent<TitleProps> = ({
  className,
  headings,
}) => {
  const getColor = (heading) => (!!heading.color ? heading.color : "white");

  const getType = (heading) =>
    Array.isArray(heading.type)
      ? useClasses(heading.type)
      : !!heading.type
      ? heading.type
      : HeadingVariant.Regular;

  const allHeadings = headings.map((heading, idx) => {
    const HeadingTag = `h${heading.priority}` as keyof JSX.IntrinsicElements;

    return (
      <HeadingTag
        key={`heading-${idx}`}
        className={getType(heading)}
        style={{ color: getColor(heading) }}
        dangerouslySetInnerHTML={{ __html: heading.value }}
      />
    );
  });

  return (
    <header className={useClasses(["titleWrap", className])}>
      {allHeadings}
    </header>
  );
};
