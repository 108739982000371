import React from "react";
import TextLoop from "react-text-loop";
import { Title } from "@components/Title";
import { HeadingVariant } from "@components/Title/types";
import { Headings } from "./headings";

interface CreateTitleProps {
  h1: string;
  h2?: string;
  h3?: string;
}

const createTitle = ({ h1, h2, h3 }: CreateTitleProps) => [
  {
    value: h3,
    priority: 3,
    type: HeadingVariant.Bold,
  },
  {
    value: h1,
    priority: 1,
  },
  {
    value: h2,
    priority: 2,
    type: [HeadingVariant.Regular, HeadingVariant.Italics],
  },
];

const allTitles = Object.values(Headings).map((headingData, idx) => (
  <Title key={idx} headings={createTitle(headingData)} />
));

export const AnimatingTitles = () => (
  <TextLoop className="textLoopWrap" interval={6000}>
    {allTitles}
  </TextLoop>
);
