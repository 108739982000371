export enum HeadingVariant {
  Regular = "regular",
  Bold = "bold",
  Italics = "italics",
}

export type Heading = {
  value: string;
  color: string;
  priority: number;
  variant: HeadingVariant | [HeadingVariant];
};
