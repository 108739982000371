import React, { useEffect, useRef } from "react";
import { navigate } from "gatsby";
import { Layout } from "@components/Layout";
import {
  ButtonCreateYourLantern,
  ButtonAnchor,
} from "@components/Button/types";
import { AnimatingTitles } from "@components/AnimatingTitles";
import "@styles/Pages.scss";

const StartPage = () => {
  const NO_INTERACTION_TIMEOUT = 15000;
  const ANIMATION_BEFORE_EXIT_DURATION = 2750;

  const startPageLayoutRef = useRef(null);

  let hasUserInteracted = false;

  const setHasUserInteracted = () => {
    hasUserInteracted = true;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!hasUserInteracted) {
        setTimeout(() => {
          navigate("/lanterns");
        }, ANIMATION_BEFORE_EXIT_DURATION - 350);
      } else {
        startPageLayoutRef.current.style.animation = null;
      }
      clearInterval(interval);
    }, NO_INTERACTION_TIMEOUT);
  });

  return (
    <>
      <style>{`
        @keyframes fadeOut {
            0% {
              opacity: 1;
              transform: scale(1);
            }
            100% {
              opacity: 0;
              transform: scale(0.95);
            }
        }
    `}</style>
      <Layout
        ref={startPageLayoutRef}
        className="startPage"
        style={{
          animation: `fadeOut ${ANIMATION_BEFORE_EXIT_DURATION}ms ease-in ${NO_INTERACTION_TIMEOUT}ms both`,
        }}
        onClick={setHasUserInteracted}
        onTouchStart={setHasUserInteracted}
        exploreLanterns
      >
        <AnimatingTitles />
        <section className="exploreButtonsWrap startPage">
          <ButtonCreateYourLantern />

          <ButtonAnchor href="/about">
            What is The Lantern Festival?
          </ButtonAnchor>
        </section>
      </Layout>
    </>
  );
};

export default StartPage;
